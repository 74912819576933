<template>
  <!-- Modal -->
  <div class="modal" id="pwdModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <form>
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Cambiar Contraseña</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-5 col-form-label">Nombre:</label>
                  <p class="col-md-7">{{ item.usu_nmbre + ' ' + item.usu_aplldo }}</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-5 col-form-label">Usuario:</label>
                  <p class="col-md-7">{{ item.usu_cdgo }}</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-5 col-form-label">Nueva contraseña (*): </label>
                  <input type="password" class="form-control col-md-7" v-model="item.usu_pwd_new">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-5 col-form-label">Confirmar: </label>
                  <input type="password" class="form-control col-md-7" v-model="item.usu_pwd_conf">
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            <button type="submit" class="btn btn-primary" data-dismiss="modal" v-on:click="$emit('change-pwd', item)">Cambiar</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
//import { focus } from 'vue-focus';

export default {
  //directives: { focus: focus },
  props: ['item'],
  data() {
    return {

    }
  },
  created: function(){

  },
  mounted: function() {

  },
  methods: {

  }
}
</script>

<style>
/*
.modal-dialog {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width:500px;
    height:300px;
}
*/
</style>
